let local = {
    treauApi:{
        endpoint: "http://localhost:5001"
    }
};

let dev = {
    treauApi:{
      endpoint: "https://api.cloud-dev.gradientcomfort.com"
    }
};

let prod = {
    treauApi:{
        endpoint: "https://api.cloud.gradientcomfort.com"
    }
};

const config  = () =>{
    console.log(process.env.REACT_APP_ENV);
    return process.env.REACT_APP_ENV === "prod"?prod:process.env.REACT_APP_ENV === "dev"?dev:local;
};

export default config();
