import * as treauAPI from "@bohe-in/treau-api-client-ts";
import config from "../config/config";

export class TreauApiClient {
    usersAPI: treauAPI.UsersApi;
    constructor() {
        this.usersAPI = new treauAPI.UsersApi(undefined,config.treauApi.endpoint);
    }
}
const treauApiClient = new TreauApiClient();

export default treauApiClient;
export {treauAPI as TreauAPI};
