import { AxiosResponse } from 'axios';
import React, { useState } from 'react';
import treauApiClient, { TreauAPI } from '../../api_clients/TreauApiClient';
import { Formik } from 'formik';
import { Form, Button } from 'react-bootstrap';
import { useToastAPIHandler } from '../../hooks/api';
import Loading from '../../components/Loading';
import * as yup from 'yup';
import logo from '../../images/Logo.png';
import background from '../../images/background.png';
import './index.scss';

const schema = yup.object({
  email: yup.string().required("This field is required").email(),
});


const InitiateAccountDeletionPage =()=>{

  const apiHandler = useToastAPIHandler();

  const [loading, setLoading] = useState(false);
  const [initiatedDeletion, setInitiatedDeletion] = useState(false);

  const initiateAccountDeletion = (email:string) =>{
    const data = { email } as TreauAPI.InitiateAccountDeletionInput;
    setLoading(true);
    apiHandler.handleRequest(
      treauApiClient.usersAPI.initiateAccountDeletion(data),
      (response: AxiosResponse<TreauAPI.ResponsesTokenExpiration>) => {
        setInitiatedDeletion(true);
      },
      undefined,
      ()=>setLoading(false)
    );
  }
  return (
    <>
      <Loading isLoading={loading} />
      <div className="delete-account-presentational" style={{ background: `url(${background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
        <div className="delete-account-content">
          <div className="logo">
            <img src={logo} alt="" className="" />
          </div>
          <div className="title">Delete Account</div>
          <div className="instructions">Enter the email address associated with your Gradient account.</div>
          <div className="initiate-form">
            <Formik
              validationSchema={schema}
              onSubmit={(formData) => initiateAccountDeletion(formData.email)}
              initialValues={{
                email: '',
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <div className="d-flex flex-column align-items-center">
                    <Form.Control
                      type="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                      placeholder='Email'
                      className='initiate-input'
                      disabled={initiatedDeletion}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  { initiatedDeletion && <p>
                    An email has been sent to your adddress. Please open it and click on the "Delete Account" link to continue with the account deletion process.
                  </p>}
                  {!initiatedDeletion && <Button
                    className='initiate-btn'
                    variant="primary"
                    size="lg"
                    onClick={() => handleSubmit()}>Send</Button>}
                  </div>
                </Form>
              )}
            </Formik>
          </div>

        </div>
      </div>
    </>
  )
}

export default InitiateAccountDeletionPage;
