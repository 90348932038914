import React from 'react';

import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../sass/common.scss';
import {
  Route,
  BrowserRouter as Router
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import InitiateAccountDeletionPage from "./InitiateAccountDeletionPage/index";
import CompleteAccountDeletionPage from "./CompleteAccountDeletionPage/loadable";

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Route path="/" exact component={InitiateAccountDeletionPage} />
      <Route path="/completeDeletion" exact component={CompleteAccountDeletionPage} />
    </Router>
  );
}

export default App;
