import React from 'react';
import Modal from 'react-bootstrap/Modal'
import './index.scss';

interface ILoadingProps {
    isLoading: boolean;
}

const Loading = (props: ILoadingProps) => {
    return (
        <Modal
            show={props.isLoading}
            style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
               
            }}
            className='d-flex'
            size="sm"
        >
            <Modal.Body style={{ minWidth:'300px'}} className='d-flex justify-content-center align-items-center flex-column'>
                <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                <h4 className="text-rose text-center mt-3">loading...</h4>
            </Modal.Body>
        </Modal>
    )
}
export default Loading;

